/* body {
    background: var(--tg-theme-bg-color) !important;
    color: var(--tg-theme-text-color) !important;
}
a {
     text-decoration: none;
}
 .objectFit-fill {
     object-fit: fill !important;
 }
 .block {
     display: block;
 }
.flex {
    display: flex;
}
.spaceBetween {
    justify-content: space-between;
}
 */

.App {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  font-family: sans-serif;
}
.App::-webkit-scrollbar {
  width: 0; /* Ширина полосы прокрутки */
  height: 0; /* Высота полосы прокрутки (для горизонтальной прокрутки) */
}
.App * {
  font-family: sans-serif !important;
  user-select: none;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  overflow: auto;
}
